<template>
  <div class="app-content content ">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h2 class="content-header-title mb-0">
                {{ convo.id ? `${convo.title}` : '' }}. Pending evaluators
              </h2>
              <p><small><span class="pe-2">{{ areas.join(', ') }}</span></small></p>
            </div>
          </div>
        </div>
        <div class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none">
          <div class="mb-1 breadcrumb-right">
            <a
              class="btn btn-icon btn-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Back to Academia Call admin"
              @click="$router.push({name: 'admin.calls.academy.index'})"
            ><i data-feather="chevron-left" /></a>
            <span class="dis-ib"><a
              class="btn btn-icon btn-primary ms-1"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasSendStatus"
              aria-controls="offcanvasSendStatus"
              @click="$store.dispatch('modals/toggleNotifyEvaluators', true)"
            ><i data-feather="send" /></a></span>
            <a
              class="btn btn-icon btn-flat-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Senior call settings"
            ><i data-feather="settings" /></a>
          </div>
        </div>
      </div>
      <div class="content-body content-body--senior-call">
        <div class="row">
          <div class="col-12">

            <div class="card">
              <div class="card-header d-flex align-items-center justify-content-between">
                <div>
                  <h4><strong>{{ evaluatorsTotal }}</strong> evaluators</h4>
                </div>
                <div>
                  <span
                    class="dis-ib"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Open filters"
                    @click="openFilters()"
                  ><a
                    class="btn btn-icon btn-icon rounded-circle btn-flat-secondary"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasFilters"
                    aria-controls="offcanvasFilters"
                  ><i data-feather="filter" /></a></span>
                  <a
                    class="btn btn-icon btn-icon rounded-circle btn-flat-secondary"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Export to Excel"
                  ><i data-feather="file" /></a>
                  <span
                    class="dis-ib"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Table settings"
                  />
                </div>
              </div>
              <div
                id="usedFilters"
                class="card-body"
              >
                Applied Filters:
                <span
                  v-for="filter, index in filters"
                  :key="index"
                  class="badge badge-light-dark ms-1"
                >
                  <template v-if="Array.isArray(filter)">
                    <template
                      v-for="str in filter"
                    >
                      {{ str.name }}
                      <a
                        :key="str.name"
                        class="ms-1 text-black"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Delete filter"
                        @click="deleteFilter(index)"
                      ><i data-feather="x" /></a>
                    </template>
                  </template>
                  <template v-else-if="typeof filter === 'object' && filter">
                    {{ filter.name }}
                    <a
                      class="ms-1 text-black"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Delete filter"
                      @click="deleteFilter(index)"
                    ><i data-feather="x" /></a>
                  </template>
                  <template v-else-if="filter">
                    {{ filter }}
                    <a
                      class="ms-1 text-black"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Delete filter"
                      @click="deleteFilter(index)"
                    ><i data-feather="x" /></a>
                  </template>
                </span>
              </div>
            </div>
            <div
              v-if="loading"
              id="loading-bg"
            >
              <div
                class="loading"
              >
                <div class="effect-1 effects" />
                <div class="effect-2 effects" />
                <div class="effect-3 effects" />
              </div>
            </div>
            <template v-else>
              <Evaluator
                v-for="evaluator in evaluators"
                :key="evaluator.id"
                :evaluator="evaluator"
                :round="filters.round ? Number.parseInt(filters.round) : round"
              />
            </template>
          </div>
        </div>
      </div>
    </div>
    <NotifyEvaluators :round="filters.round ? Number.parseInt(filters.round) : round" />
    <ConvoFilter
      :filter-path="'convo/filterEvaluators'"
    >
      <template #filters>
        <div class="mb-1">
          <label
            for="roundcall"
            class="form-label"
          >Round</label>
          <select
            id="roundcall"
            name="roundcall"
            class="form-select select2"
            @change="saveFilter($event.target.value, 'round')"
          >
            <option
              value=""
              selected
            >
              All
            </option>
            <option value="5">
              Round 0
            </option>
            <option value="6">
              Round 1
            </option>
          </select>
        </div>
        <div class="mb-1">
          <label
            for="nameseniorcall"
            class="form-label"
          >Evaluator name</label>
          <input
            type="text"
            class="form-control"
            name="nameseniorcall"
            @change="saveFilter($event.target.value, 'name')"
          >
        </div>

        <div class="mb-1">
          <label
            for="areaseniorcall"
            class="form-label"
          >Area</label>
          <v-select
            label="name"
            :options="areasAll"
            @input="saveFilter($event.code, 'area')"
          />
        </div>
        <div class="mb-1">
          <label
            for="areaseniorcall"
            class="form-label"
          >Pending</label>
          <select
            id="areaseniorcall"
            name="areaseniorcall"
            class="form-select select2"
            @change="saveFilter($event.target.value, 'pending')"
          >
            <option
              value=""
              selected
            >
              All
            </option>
            <option value="1">
              Yes
            </option>
            <option value="0">
              No
            </option>
          </select>
        </div>
        <div class="mb-1">
          <div class="form-check">
            <input
              id="flexCheckDefault"
              class="form-check-input"
              type="checkbox"
              @change="saveFilter($event.target.checked, 'moderator')"
            >
            <label
              class="form-check-label"
              for="flexCheckDefault"
            >Hide moderators</label>
          </div>
        </div>
      </template>
    </ConvoFilter>
    <div class="d-flex align-items-center justify-content-between">
      <Paginator
        :total="evaluatorsTotal"
        :store="'convo/fetchPendingEvaluators'"
        @loading="loading = !loading"
      />
      <div>
        <select
          v-model="perPage"
          style="margin-top: -10px;"
          class="form-select"
          @change="setPerPage"
        >
          <option value="15">
            15 items per page
          </option>
          <option value="50">
            50 items per page
          </option>
          <option value="75">
            75 items per page
          </option>
          <option value="10000">
            All items per page
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Paginator from '@/views/back/partials/Paginator.vue'
import vSelect from 'vue-select'
import Evaluator from './components/Evaluator.vue'
import NotifyEvaluators from './components/NotifyEvaluators.vue'
import ConvoFilter from '../../partials/offcanvas/ConvoFilter.vue'

export default {
  components: {
    Evaluator,
    ConvoFilter,
    NotifyEvaluators,
    Paginator,
    vSelect,
  },
  data() {
    return {
      loading: true,
      round: null,
    }
  },
  computed: {
    ...mapGetters({
      convo: 'convo/convo',
      evaluators: 'convo/evaluators',
      evaluatorsTotal: 'convo/evaluatorsTotal',
      areasAll: 'areas/areas',
      filters: 'filters/filters',
      perPage: 'pagination/perPage',
    }),
    areas() {
      if (this.convo.areas) {
        return this.convo.areas.map(e => e.code)
      }

      return []
    },
  },
  async mounted() {
    if (this.areasAll.length === 0) {
      this.$store.dispatch('areas/filter', this.$store.getters['filters/filters'])
    }
    await this.$store.dispatch('convo/fetchSpecific', this.$route.params.id)
    this.$store.dispatch('convo/fetchPendingEvaluators', this.$route.params.id)
    this.loading = false
    this.round = this.convo.current_access ? this.convo.current_access.type : 0
    setTimeout(() => {
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 200)
  },
  methods: {
    openFilters() {
      this.$store.dispatch('modals/toggleConvoFilters', true)
    },
    saveFilter(value, field) {
      this.$store.dispatch('filters/saveFilters', {
        value,
        field,
      })
    },
    async setPerPage(perPage) {
      this.loading = true

      this.$store.dispatch('pagination/clearPage')
      await this.$store.dispatch('pagination/savePerPage', perPage.target.value)
      await this.$store.dispatch('convo/fetchPendingEvaluators', this.filters)

      this.loading = false
      setTimeout(() => {
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 200)
    },
    async deleteFilter(index) {
      const { filters } = this
      this.$store.dispatch('filters/deleteFilters', index)

      this.$store.dispatch('filters/saveFilters', this.filters)
      await this.$store.dispatch('convo/filterEvaluators', this.filters)
      setTimeout(() => {
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 250)
    },
  },
}
</script>
